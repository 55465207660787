import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";
import creditCardType from "credit-card-type";
import Utils from "byzantine/src/utils";

/**
 *
 * NDS TextInput configured for entering a debit/credit card.
 * `CardNumberTextInput` is intended to be used as a controlled component.
 * Only numeric characters are permitted,
 * and the value displayed will be formatted as the user types
 * However, the value transmitted up to the parent via `onChange` will only include numeric characters as XXXXXXXXX
 *
 */

export const getMaxLengthWithoutSpaces = (initialValue) => {
  if (Utils.isPossiblyAnAmexCardNumber(initialValue)) {
    return 15;
  }
  // Removed 14 char limit for diners club because they can in fact be 16.
  // This condition [directly contradicts our formatting logic](https://github.com/narmi/banking/blob/837cc7f44802460fbf521fa2c9baddaaf800c39c/byzantine/src/utils.ts#L216)
  //
  // Fixes: <https://linear.app/narmi/issue/NDS-987/card-number-form-cards-starting-with-55-incorrect-limits-to-14-digits>
  //
  // if (Utils.isPossiblyADinersClubCardNumber(initialValue)) {
  //   return 14;
  // }
  return 16;
};

const parseCardType = (cardType) => (cardType === "american-express" ? "amex" : cardType);

export const validateCardType = (value, cardNetwork = []) => {
  const expectedCardNumberLength = getMaxLengthWithoutSpaces(value);
  if (value.length < expectedCardNumberLength) {
    return `Card number must be ${expectedCardNumberLength} digits long`;
  }

  // Luhn's algorithm
  let doubles = 0;
  let remainders = 0;
  value
    .split("")
    .reverse()
    .forEach((val, index) => {
      if (index % 2 !== 0) {
        let doubled = parseInt(val, 10) * 2;
        if (doubled > 9) doubled -= 9;
        doubles += doubled;
      } else remainders += parseInt(val, 10);
    });
  if ((doubles + remainders) % 10 !== 0) return "Please enter a valid credit card number";

  const possibleCardTypes = creditCardType(value);
  if (possibleCardTypes.length > 1 || possibleCardTypes.length === 0) {
    return "Please enter a valid credit card number";
  }

  const cardType = parseCardType(possibleCardTypes[0].type);
  if (!cardNetwork.includes(cardType)) {
    return `${possibleCardTypes[0].niceType} cards are not currently supported`;
  }
  return null;
};

const CardNumberTextInput = ({
  value = "",
  onChange,
  label,
  name = "card_number",
  id = "card_number",
  ...otherTextInputProps
}) => {
  const getDisplayValue = () => {
    return Utils.formatCardNumber(value);
  };

  return (
    <TextInput
      id={id}
      name={name}
      label={label}
      value={getDisplayValue()}
      autoComplete="cc-number"
      onChange={(event) => {
        const strippedValue = event.target.value
          .replace(/[^\d]/g, "")
          .substring(0, getMaxLengthWithoutSpaces(event.target.value));

        // allows for deletion of inputted value if last displayed char is non-numeric
        // if the stripped target value is the same as the stored parent value, then
        // the backspace action deleted a non-numeric character.
        if (event.nativeEvent.inputType === "deleteContentBackward" && strippedValue === value) {
          onChange(value.substring(0, value.length - 1));
          return;
        }
        onChange(strippedValue);
      }}
      inputMode="numeric"
      {...otherTextInputProps}
    />
  );
};
CardNumberTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default CardNumberTextInput;
