/* eslint-disable import/export */
import {
  achPayments,
  loanOptionality,
  travelNotes,
  wireTemplates,
  cardLimitIncrease,
  positivePay,
  aoiob,
} from "./src/dbbl/businessLogic/modules";

export type { IPlatform } from "./src/dbbl/interfaces/platform.interface";
export { PLATFORM } from "./src/dbbl/interfaces/platform.interface";

export type { INetwork } from "./src/dbbl/interfaces/network.interface";
export type { Network } from "./src/dbbl/interfaces/network.types";
export { ResponseError } from "./src/dbbl/interfaces/network.types";

export type { IToasts } from "./src/dbbl/interfaces/toasts.interface";

export { default as LibraryProvider } from "./src/dbbl/providers/library.provider";

export { default as ComposeProviders } from "./src/dbbl/providers/composeProviders";

export type * from "./src/dbbl/composites/request/request.types";
export * from "./src/dbbl/composites/request/request.types";

export * from "./src/dbbl/composites/request/request.helpers";
export * from "./src/dbbl/composites/request/request.hooks";

export type * from "./src/dbbl/composites/request/errors/errorHandler.types";
export * from "./src/dbbl/composites/request/errors/useErrorHandling.hook";
export * from "./src/dbbl/composites/request/request.utilts";

export type * from "./src/types/models/v1/Error.types";
export * from "./src/types/models/v1/Error.types";
export * from "./src/types/models/v1/Feature.types";

export * from "./src/dbbl/hooks";

export { phoneFormatter, formatAddress, masks } from "./src/dbbl/utils";
export * from "./src/dbbl/hooks";

export {
  reducers,
  accountSortingActions,
  userActions,
  accountsActions,
  endToEndTestActions,
  selectEndToEndTest,
  type AccountsSliceState,
  useFetchInstitution,
  useFetchUser,
  useInstitutionHelpers,
  selectAccountSorting,
  selectAccountsById,
  selectBAccount,
  selectPayableLoanAccounts,
  selectLoansPayableByCard,
  selectGroupedLoanAccounts,
  selectAccounts,
  selectAllAccounts,
  selectInternalAccounts,
  selectMinimumPayment,
  selectAccountIdWithCertainty,
  selectGroupedLoanAccountsPayableByCard,
  createDeepEqualSelector,
  ACCOUNT_PRODUCT_GROUP,
  useUser,
  createGroups,
  isBusinessUser,
  businessPermissions,
  dualApprovalRequired,
  type RootState,
  type PreloadedState,
  DEFAULT_STATE,
} from "./src/dbbl/businessLogic/entities";

export * as entities from "./src/dbbl/businessLogic/entities";

export { createBootstrap } from "./src/dbbl/businessLogic/bootstrap";

export {
  centsToDollars,
  dollarsToCents,
  dollars,
  zeroDollars,
  zeroCents,
  cents,
  sanitizeTextCurrency,
  currencyToCents,
  getInForm,
} from "./src/dbbl/utils";

export * from "./src/dbbl/businessLogic/modules";

export const modules = {
  wireTemplates,
  achPayments,
  travelNotes,
  cardLimitIncrease,
  loanOptionality,
  positivePay,
  aoiob,
};
