import { Button } from "cerulean";
import utils from "byzantine/src/utils";
import { entities, useFeature, useNestedMenu } from "byzantine";
import { memo } from "react";

import Details from "../../Details";
import { isAppItem, isLinkItem, isNafItem, isAoiobItem } from "../utils";
import LinkItem from "./linkItem.component";
import AppItem from "./appItem.component";
import PositivePayItem from "./PositivePayItem.component";
import AOIOBItem from "./aoiobItem.component";
import { LegacyNafUrl } from "../../../types";

type Props = {
  legacyNafUrls: LegacyNafUrl[];
};

const NestedItem = ({ legacyNafUrls }: Props) => {
  const { nestedMenu, menuName } = useNestedMenu();
  const { featureEnabled } = useFeature();
  const listOfNafItems = entities.institution.useNAFItems();

  legacyNafUrls.forEach((link) => {
    listOfNafItems[link.title] = { url: link.url, openNewTab: true };
  });

  if (!nestedMenu) {
    return null;
  }

  const renderedItems = nestedMenu
    .reduce((items: React.ReactElement[], item) => {
      let newItems: React.ReactElement[] = [];
      if (isLinkItem(item.itemProps)) {
        newItems = [
          <LinkItem
            item={{ name: item.name, itemProps: item.itemProps }}
            key={item.name}
          />,
        ];
      }

      // This is a NAF app
      if (isAppItem(item.itemProps)) {
        newItems = [
          <AppItem
            item={{ name: item.name, itemProps: item.itemProps }}
            key={item.name}
          />,
        ];
      }

      // This item represents a list of _all_ NAF options, so we create
      // a list of individual Link and App items.
      // This ensures chunking layout logic can account for the number of NAF links
      if (isNafItem(item.itemProps)) {
        newItems = Object.entries(listOfNafItems).map(
          ([nafName, nafItemProps]) => {
            if (isLinkItem(nafItemProps)) {
              return (
                <LinkItem
                  item={{ name: nafName, itemProps: nafItemProps }}
                  key={nafName}
                />
              );
            }
            if (isAppItem(nafItemProps)) {
              return (
                <AppItem
                  item={{ name: nafName, itemProps: nafItemProps }}
                  key={nafName}
                />
              );
            }
            return <></>;
          },
        );
      }

      if (isAoiobItem(item.itemProps)) {
        newItems = [<AOIOBItem />];
      }

      return [...items, ...newItems];
    }, [])
    .filter(Boolean);

  const unpackedItems: React.ReactElement[] = renderedItems.flatMap((item) => {
    if (item && item.key !== "naf") {
      return item;
    }
    return item?.props.children;
  });

  if (featureEnabled("positive_pay")) {
    unpackedItems.push(<PositivePayItem key="positivePay" />);
  }

  const chunkSize = unpackedItems.length % 5 !== 1 ? 5 : 6;

  const chunkedItems = utils.shardArrayByChunkSize(unpackedItems, chunkSize);

  return (
    <Details
      summary={<Button as="a" kind="menu" label={menuName} />}
      key={menuName}
      type="wide details"
    >
      {chunkedItems.map((menuChunk, index) => (
        <div key={index} className="tools">
          {menuChunk}
        </div>
      ))}
    </Details>
  );
};

export default memo(NestedItem);
